<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text">
        {{ getTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="setAddress-form" v-model="isFormValid" lazy-validation>
          <v-row>
            <v-col cols="12" :lg="showGmap ? 6 : 12">
              <v-row>
                <v-col cols="12" class="pa-1">
                  <v-text-field
                    dense
                    outlined
                    filled
                    :value="prestador.preNom"
                    label="Prestador"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pa-1">
                  <v-text-field
                    class="readonly"
                    dense
                    outlined
                    filled
                    :value="prestador.oriNom"
                    label="Origen"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="pa-1">
                  <!-- País -->
                  <v-select
                    v-model="paisSelected"
                    :items="paises"
                    label="País"
                    item-text="value"
                    item-value="id"
                    outlined
                    dense
                    @change="loadProvincias(paisSelected)"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <!-- Provincia -->
                  <v-select
                    v-model="provinciaSelected"
                    :items="provincias"
                    label="Provincia"
                    item-text="value"
                    item-value="id"
                    no-data-text="Seleccione un país"
                    outlined
                    dense
                    :rules="rules.required"
                    @change="loadLocalidades(provinciaSelected)"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <!-- Localidad -->
                  <v-autocomplete
                    v-model="localidadSelected"
                    :items="localidades"
                    item-text="value"
                    item-value="id"
                    label="Localidad"
                    no-data-text="Seleccione una provincia"
                    outlined
                    dense
                    clearable
                    autocomplete="not"
                    :rules="rules.required"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <!-- Barrio -->
                  <v-autocomplete
                    v-model="barrioSelected"
                    :items="barrios"
                    item-text="value"
                    item-value="id"
                    label="Barrio"
                    outlined
                    dense
                    clearable
                    autocomplete="off"
                    :rules="rules.required"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!-- Domicilio en cartilla: Prestador / Prestador del Origen -->
              <div v-if="prestador.opcionDomicilio !== 2">
                <v-row>
                  <v-col cols="12" md="9" class="pa-1">
                    <v-text-field
                      dense
                      outlined
                      v-model.trim="prestadorAddress.calle"
                      label="Calle"
                      :rules="
                        rules.required.concat([
                          rules.minLength(prestadorAddress.calle, 3),
                          rules.requiredTrim(prestadorAddress.calle)
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field
                      dense
                      outlined
                      v-model="prestadorAddress.numero"
                      label="Número"
                      type="number"
                      :rules="rules.positiveNumber.concat(rules.required)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field
                      dense
                      outlined
                      v-model="prestadorAddress.piso"
                      label="Piso"
                      type="number"
                      :rules="rules.positiveNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field
                      dense
                      outlined
                      v-model="prestadorAddress.dpto"
                      label="Dpto"
                      :rules="[rules.maxLength(prestadorAddress.dpto, 4)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field
                      dense
                      outlined
                      v-model="prestadorAddress.codPostal"
                      label="Cód. postal"
                      type="number"
                      :rules="rules.positiveNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- Domicilio en cartilla: Origen -->
              <div v-else>
                <v-row>
                  <v-col cols="12" class="pa-1">
                    <v-text-field
                      dense
                      outlined
                      v-model.trim="prestadorAddress.oriDom"
                      label="Domicilio"
                      :rules="
                        rules.required.concat([
                          rules.minLength(prestadorAddress.oriDom, 4),
                          rules.requiredTrim(prestadorAddress.oriDom)
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  :sm="showGmap ? 6 : 12"
                  :md="showGmap ? 7 : 12"
                  class="pa-1"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="prestadorAddress.telefono"
                    label="Teléfono"
                    autocomplete="not"
                    :rules="
                      rules.telephone.concat([
                        rules.maxLength(prestadorAddress.telefono, 15)
                      ])
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" offset-md="1" class="pa-1">
                  <v-btn
                    v-if="showGmap"
                    class="btnSearchOnMap"
                    :disabled="!isFormValid"
                    type="submit"
                    form="form"
                    @click="searchOnMap"
                  >
                    Buscar en mapa
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6" class="pt-1" v-if="showGmap">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="formattedAddress"
                    label="Ubicación en mapa"
                    dense
                    outlined
                    filled
                    readonly
                    :hint="helpText"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <GmapMap
                ref="mapRef"
                class="my-map"
                id="myMap"
                :options="mapOptions"
                :center="center"
                :zoom="zoom"
                map-type-id="roadmap"
                style="width: 100%; height: 420px"
                @dblclick="updateCoordinates"
              >
                <GmapMarker
                  :position="marker"
                  :clickable="true"
                  :draggable="true"
                  @dragend="updateCoordinates"
                  @click="center = marker"
                />
              </GmapMap>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <div class="ma-0 pt-1" v-if="showGmap">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  size="28"
                  @click="resetFormattedAddress"
                >
                  mdi-map-marker-remove
                </v-icon>
              </template>
              <span>Limpiar ubicación geográfica</span>
            </v-tooltip>
          </div>
          <v-btn outlined @click="$emit('close')">
            Cerrar
          </v-btn>
          <v-btn
            class="ml-4 mr-4"
            color="primary"
            :disabled="!isFormValid"
            type="submit"
            form="form"
            @click="saveChanges"
          >
            Guardar
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index";
import rules from "@/utils/helpers/rules";

export default {
  name: "SetAddressOnMap",
  props: {
    prestador: {
      type: Object,
      required: true
    },
    showGmap: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      isFormValid: true,
      helpText: enums.modules.prestadores.message.HELP_TEXT,
      formattedAddress: null,
      title: enums.titles.SET_ADDRESS_ON_MAP,
      titleByOpcionDomicilio: [
        {
          id: 1,
          value: " (prestador relacionado al origen)"
        },
        {
          id: 2,
          value: " (origen)"
        },
        {
          id: 3,
          value: " (prestador)"
        }
      ],
      zoom: 13,
      paises: [],
      provincias: [],
      localidades: [],
      barrios: [],
      paisSelected: 1,
      provinciaSelected: null,
      localidadSelected: null,
      barrioSelected: null,
      prestadorAddress: {},
      map: null,
      inputSearch: null,
      marker: null,
      geocoder: null,
      center: { lat: -34.603722, lng: -58.381592 }, // si no hay coordenadas, centra en capital federal
      places: [],
      currentPlace: null,
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false
      },
      rules: rules
    };
  },

  computed: {
    getTitle() {
      return (
        this.title +
        this.titleByOpcionDomicilio.find(
          x => x.id === this.prestador.opcionDomicilio
        ).value
      );
    },
    coordinatesPrestador() {
      return { lat: this.prestadorAddress.lat, lng: this.prestadorAddress.lng };
    },
    google: gmapApi
  },

  mounted() {},

  watch: {
    marker() {
      this.decodeLatLong();
    },
    prestadorAddress() {
      this.setPrestadorValues();
    }
  },
  created() {
    this.loadPrestador();
  },
  methods: {
    ...mapActions({
      getPrestadorGeoLoc: "prestadores/getPrestadorGeoLoc",
      getOrigenGeoLoc: "prestadores/getOrigenGeoLoc",
      updatePrestadorAddress: "prestadores/updatePrestadorAddress",
      updateOrigenAddress: "prestadores/updateOrigenAddress",
      getPrestadorGeocodeAddress: "prestadores/getPrestadorGeocodeAddress",
      getOrigenGeocodeAddress: "prestadores/getOrigenGeocodeAddress",
      getPaises: "shared/getPaises",
      getProvinciasByPaisId: "shared/getProvinciasByPaisId",
      getLocalidadesByProvId: "shared/getLocalidadesByProvId",
      getBarrios: "shared/getBarrios",
      getLocalidades: "shared/getLocalidades",
      getProvincias: "shared/getProvincias",
      setAlert: "user/setAlert"
    }),
    initMap() {
      this.$refs.mapRef.$mapPromise.then(map => {
        if (this.coordinatesPrestador) {
          map.panTo(this.coordinatesPrestador);
          this.marker = this.coordinatesPrestador;
          map.bounds;
        } else {
          this.marker = this.center;
        }
        this.geocoder = new google.maps.Geocoder();
        this.decodeLatLong();
      });
    },
    async loadTaxonomy() {
      const resPaises = this.getPaises();
      this.paises = await resPaises;
      const resBarrios = this.getBarrios();
      this.barrios = await resBarrios;
      const resProv = this.getProvincias();
      this.provincias = await resProv;
      const resLocal = this.getLocalidades();
      this.localidades = await resLocal;
      this.setTaxonomy();
    },
    setTaxonomy() {
      let barrio = this.barrios.find(x => x.id === this.prestadorAddress.barId);
      this.barrioSelected = barrio ? barrio.id : 1;
      let prov = this.provincias.find(
        x => x.id === this.prestadorAddress.provId
      );
      this.provinciaSelected = prov ? prov.id : 1;
      let localidad = this.localidades.find(
        x => x.id === this.prestadorAddress.locId
      );
      this.localidadSelected = localidad ? localidad.id : 1;
    },
    async loadProvincias(paisId) {
      const res = await this.getProvinciasByPaisId(paisId);
      this.provincias = res;
    },
    async loadLocalidades(provId) {
      const res = await this.getLocalidadesByProvId(provId);
      this.localidades = res;
    },
    async loadPrestador() {
      if (this.prestador.opcionDomicilio == 2) {
        const res = await this.getOrigenGeoLoc({ oriId: this.prestador.oriId });
        this.prestadorAddress = res;
      } else {
        const res = await this.getPrestadorGeoLoc({
          preId: this.prestador.preIdRadic,
          tipoRadicId: this.prestador.tipoRadicId
        });
        this.prestadorAddress = res;
      }
      await this.loadTaxonomy();
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },
    async searchOnMap() {
      const provincia = this.provincias.find(
        x => x.id === this.provinciaSelected
      ).value;
      const pais = this.paises.find(x => x.id === this.paisSelected).value;
      const localidad = this.localidades.find(
        x => x.id === this.localidadSelected
      );
      const data = {
        localidad: localidad.value,
        locId: localidad.id,
        provincia: provincia,
        pais: pais
      };
      if (this.prestador.opcionDomicilio == 2) {
        data["domicilio"] = this.prestadorAddress.oriDom;
        const res = await this.getOrigenGeocodeAddress(data);
        this.updateMarker(res);
      } else {
        data["calle"] = this.prestadorAddress.calle;
        data["numero"] = this.prestadorAddress.numero;
        const res = await this.getPrestadorGeocodeAddress(data);
        this.updateMarker(res);
      }
    },
    setPrestadorValues() {
      this.formattedAddress = this.prestadorAddress.domicilioCartilla || null;
      if (this.prestadorAddress.lat && this.prestadorAddress.lng) {
        this.marker = this.coordinatesPrestador;
        this.center = this.marker;
        this.zoom = 15;
      }
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.marker = { ...marker };
        this.zoom = 15;
        this.center = marker;
        this.currentPlace = null;
      }
    },
    // Convert coordinatesPrestador to address
    decodeLatLong() {
      this.$gmapApiPromiseLazy().then(() => {
        if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
        // eslint-disable-next-line no-undef
        if (this.marker) {
          this.geocoder.geocode(
            { location: this.marker },
            (results, status) => {
              if (status === "OK") {
                this.formattedAddress = results[0].formatted_address;
              }
            }
          );
        }
      });
    },
    updateMarker($event) {
      if ($event.lat && $event.lng) {
        this.marker = {
          lat: $event.lat,
          lng: $event.lng
        };
        this.zoom = 15;
        this.center = this.marker;
      }
    },
    updateCoordinates($event) {
      this.marker = {
        lat: $event.latLng.lat(),
        lng: $event.latLng.lng()
      };
      this.zoom = 15;
      this.center = this.marker;
      this.decodeLatLong();
    },
    resetFormattedAddress() {
      this.formattedAddress = null;
      this.marker = null;
    },
    async saveChanges() {
      let data = {
        paisId: this.paisSelected,
        provId: this.provinciaSelected,
        locId: this.localidadSelected,
        barId: this.barrioSelected,
        telefono: this.prestadorAddress.telefono
      };
      if (this.showGmap) {
        data["lat"] = this.marker ? this.marker.lat : null;
        data["lng"] = this.marker ? this.marker.lng : null;
        data["domicilioCartilla"] = this.formattedAddress;
      }
      // origen
      if (this.prestador.opcionDomicilio == 2) {
        data["oriId"] = this.prestador.oriId;
        data["oriDom"] = this.prestadorAddress.oriDom || "";

        const res = await this.updateOrigenAddress(data);

        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
        }
      }
      // prestador - prestador del origen
      else {
        data["preIdRadic"] = this.prestador.preIdRadic;
        data["tipoRadicId"] = this.prestador.tipoRadicId;
        data["calle"] = this.prestadorAddress.calle;
        data["numero"] = this.prestadorAddress.numero;
        data["piso"] = this.prestadorAddress.piso;
        data["dpto"] = this.prestadorAddress.dpto;
        data["codPostal"] = this.prestadorAddress.codPostal;

        const res = await this.updatePrestadorAddress(data);

        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
        }
      }
      this.$emit("closeAndReload", {});
    }
  }
};
</script>

<style scoped>
.form-input {
  border: 1px solid #9fa5a8;
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
}
.label-autocomplete {
  color: #195472;
  margin-bottom: 0px;
}
.help2 {
  margin-bottom: 0px;
  margin-top: -10px;
  font-size: 12px;
  color: #6c6c6c;
  padding-left: 12px;
}
.btnSearchOnMap {
  text-transform: none;
}
</style>
