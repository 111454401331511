<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" />
        <PageHeader :title="title" class="pb-0" />
        <div v-if="modalMapa">
          <v-dialog
            v-model="modalMapa"
            :max-width="showGmap ? '85%' : ' 40%'"
            @keydown.esc="modalMapa = false"
          >
            <SetAddressOnMap
              :prestador="prestadorSelected"
              :showGmap="showGmap"
              @close="modalMapa = false"
              @closeAndReload="closeSetAddressModal"
            />
          </v-dialog>
        </div>

        <v-card class="mx-auto my-12 mt-2 mb-0" max-width="1200" outlined>
          <v-container class="pt-0" :class="!showFilters ? 'pb-0' : 'pb-2'">
            <v-row class="pr-4" justify="end">
              <v-col class="py-0" cols="11" align="left">
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col class="py-0" cols="1" align="right" align-self="center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row v-if="filtersTop">
                  <!-- Prestador -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-autocomplete
                      ref="prestador-autocomplete"
                      v-model="prestadorSearchInput"
                      :items="prestadoresSearchResult"
                      :search-input.sync="prestadorTextInput"
                      outlined
                      @change="prestadorChange(prestadorSearchInput)"
                      item-text="value"
                      item-value="id"
                      dense
                      clearable
                      autocomplete="off"
                      label="Prestador"
                      return-object
                      ><template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="prestadoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Origen -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-autocomplete
                      ref="origen-autocomplete"
                      v-model="origenSearchInput"
                      :items="origenesSearchResult"
                      :search-input.sync="origenesAutocompleteInput"
                      outlined
                      item-text="value"
                      item-value="id"
                      dense
                      clearable
                      return-object
                      autocomplete="off"
                      label="Origen"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="origenesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Convenio -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-autocomplete
                      v-model="filtersTop.convenios.model"
                      :items="convenios"
                      item-text="value"
                      item-value="id"
                      label="Convenio"
                      outlined
                      dense
                      clearable
                      autocomplete="new-password"
                      @change="loadPlanes(filtersTop.convenios.model)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <!-- Plan -->
                    <v-select
                      v-model="planSelected"
                      :items="planes"
                      label="Plan"
                      item-text="value"
                      item-value="id"
                      :no-data-text="
                        filtersTop.convenios.model
                          ? 'No hay datos disponibles'
                          : 'Seleccione un convenio'
                      "
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <!-- Especialidad -->
                    <v-autocomplete
                      v-model="filtersTop.especialidades.model"
                      :items="especialidades"
                      label="Especialidad"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      autocomplete="off"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <!-- Localidad -->
                    <v-autocomplete
                      v-model="filtersTop.localidades.model"
                      :items="localidades"
                      item-text="value"
                      item-value="id"
                      label="Localidad"
                      outlined
                      dense
                      clearable
                      autocomplete="off"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    :md="gMapsApiKey ? 2 : 3"
                    class="py-0"
                  >
                    <!-- Mostrar ocultos -->
                    <v-select
                      v-model="toShowSelected"
                      :items="toShow"
                      class="my-placeholder"
                      label="Visibles/Ocultos"
                      item-text="value"
                      item-value="id"
                      clearable
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    :md="gMapsApiKey ? 2 : 3"
                    class="py-0"
                  >
                    <!-- Mostrar farmacias -->
                    <v-select
                      v-model="esFarmacia"
                      :items="farmacia"
                      class="my-placeholder"
                      item-text="value"
                      item-value="id"
                      label="Prestador/Farmacias"
                      clearable
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    v-if="gMapsApiKey"
                    cols="12"
                    sm="6"
                    md="2"
                    class="py-0"
                  >
                    <!-- Mostrar geoloc -->
                    <v-select
                      v-model="geolocSelected"
                      :items="geolocToShow"
                      item-text="value"
                      item-value="id"
                      label="Geolocalización"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4 py-0" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                    :loading="loading"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-tabs background-color="transparent" v-if="showGrid" v-model="tabs">
          <v-tab href="#lista">Lista</v-tab>
          <v-tab @click="initMap" v-show="gMapsApiKey" href="#mapa">Mapa</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="lista">
            <v-data-table
              id="prestadoresTable"
              v-if="showGrid"
              :headers="headers"
              :header-props="{ sortIcon: null }"
              class="elevation-1"
              :items="cartillaPrestadores"
              :loading="loading"
              :footer-props="{
                'items-per-page-options': itemsPerPageOptions
              }"
              :items-per-page="itemPerPage"
            >
              <template v-slot:[`item.hasConfig`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-readonly"
                      v-bind="attrs"
                      v-on="on"
                      v-show="item.ocultarPrestador || item.ocultarOrigen"
                      small
                    >
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>Oculto en cartilla</span>
                </v-tooltip>
                <v-tooltip left v-if="gMapsApiKey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-readonly"
                      v-bind="attrs"
                      v-on="on"
                      v-show="item.geoloc"
                      small
                    >
                      mdi-map-check
                    </v-icon>
                  </template>
                  <span>Tiene geolocalización</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left v-if="canEditInfo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      @click="editPrestador(true, item.preId, item.oriId)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar información</span>
                </v-tooltip>
                <v-tooltip left v-if="canEditGeoLoc">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      @click="editAddres(item)"
                    >
                      mdi-map-marker
                    </v-icon>
                  </template>
                  <span>Editar ubicación</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="mapa">
            <div id="gmap-spinner" v-show="loading">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                :indeterminate="loading"
              ></v-progress-circular>
            </div>
            <v-container id="gmap-container">
              <GmapMap
                ref="myMap"
                class="my-map"
                id="myMap"
                :options="mapOptions"
                :center="center"
                :zoom="zoom"
                map-type-id="roadmap"
                style="width: 100%; height: 480px"
              >
                <GmapInfoWindow
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick="infoWinOpen = false"
                />

                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  @dblclick="selectedMarker(m)"
                  @click="toggleInfoWindow(m, index)"
                />
              </GmapMap>
              <v-row v-if="gMapsApiKey">
                <v-col cols="12" align="end">
                  <v-btn
                    color="primary"
                    @click="openFirstDialogGeolocalizacion()"
                    class="to-right"
                  >
                    Geolocalización masiva
                  </v-btn>
                </v-col>
              </v-row>
              <v-dialog
                v-if="openFirstModal === true"
                v-model="openFirstModal"
                max-width="550px"
                @keydown.esc="closeModalGeolocalizacion"
              >
                <v-card>
                  <v-container>
                    <v-card-title>
                      <span class="primary--text">{{
                        firstDialogGeolocalizacionTitle
                      }}</span>
                    </v-card-title>
                    <v-card-text>{{
                      firstDialogGeolocalizacionText
                    }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="closeModalGeolocalizacion">
                        Cancelar
                      </v-btn>
                      <v-btn
                        @click="openSecondModalGeolocalizacion"
                        color="primary"
                      >
                        Generar masivamente
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
              <v-dialog
                v-if="openSecondModal === true"
                v-model="openSecondModal"
                max-width="550px"
                @keydown.esc="closeModalGeolocalizacion"
              >
                <v-card>
                  <v-container>
                    <v-card-title>
                      <span class="primary--text">{{
                        secondDialogGeolocalizacionTitle
                      }}</span>
                    </v-card-title>
                    <v-card-text>{{
                      secondDialogGeolocalizacionText
                    }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="closeModalGeolocalizacion">
                        Cancelar
                      </v-btn>
                      <v-btn
                        @click="generarGeolocalizacionMasiva()"
                        color="primary"
                        :loading="loadingGeolocalizacion"
                        :disabled="loadingGeolocalizacion"
                      >
                        Continuar
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
        <div v-if="openModalEdit">
          <EditPrestadorCartilla
            :prestadorId="prestadorId"
            :origenId="origenId"
            @close="editPrestador"
          ></EditPrestadorCartilla>
        </div>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import SetAddressOnMap from "@/components/modules/prestadores/SetAddressOnMap";
import { mapActions, mapGetters } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditPrestadorCartilla from "@/components/modules/prestadores/EditPrestadorCartilla";
import { gmapApi } from "vue2-google-maps";
import { loadGmapApi } from "vue2-google-maps";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfiguracionCartilla",
  components: {
    PageHeader,
    FiltersSelected,
    EditPrestadorCartilla,
    SetAddressOnMap,
    Ayuda,
    GoBackBtn
  },

  data() {
    return {
      markers: [],
      routeToGo: "ConfiguracionPrestadores",
      loadingGeolocalizacion: false,
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false
      },
      firstDialogGeolocalizacionTitle:
        enums.titles.DIALOG_GEOLOCALIZACION_MASIVA_TITLE,
      firstDialogGeolocalizacionText:
        enums.titles.DIALOG_GEOLOCALIZACION_MASIVA,
      secondDialogGeolocalizacionTitle:
        enums.titles.SECOND_DIALOG_GEOLOCALIZACION_TITLE,
      secondDialogGeolocalizacionText:
        enums.titles.SECOND_DIALOG_GEOLOCALIZACION,
      center: { lat: -34.603722, lng: -58.381592 }, // si no hay coordenadas, centra en capital federal
      zoom: 13,
      tabs: null,
      prestadoresFiltered: [],
      itemsPerPageOptions: [10, 20, 30, 40, 50],
      itemPerPage: 10,
      gMapsApiKey: null,
      filtersApplyed: [],
      showGmap: false,
      planSelected: null,
      prestadorSelected: {},
      modalMapa: false,
      infoOptions: {
        content: "",
        // optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWinOpen: false,
      openFirstModal: false,
      currentMidx: null,
      infoWindowPos: null,
      openSecondModal: false,
      optionCode: enums.webSiteOptions.CONFIGURACION_CARTILLA,
      showHelp: false,
      showIcon: true,
      showExpand: false,
      origenesAutocompleteInput: null,
      headers: [
        {
          text: "Prestador",
          align: "start",
          value: "preNom"
        },
        { text: "Origen", value: "oriNom" },
        { text: "Obra social", value: "osNom" },
        { text: "Plan", value: "planNom" },
        { text: "Tipo", value: "profesion" },
        { text: "Especialidad", value: "especialidad" },
        {
          text: "Domicilio",
          value: "domicilio",
          sortable: false,
          width: "220px"
        },
        { text: "Teléfono", value: "telefono", sortable: false },
        { text: "Config.", value: "hasConfig" },
        { text: "Editar", value: "actions", sortable: false }
      ],
      prestadoresLoading: false,
      origenInputSelected: null,
      origenTemp: null,
      prestadorTemp: null,
      showFilters: true,
      showFiltersSelected: false,
      geolocSelected: 1,
      showGrid: false,
      title: enums.titles.CONFIGURACION_CARTILLA,
      snbMsg: enums.modules.prestadores.message.SNB_ADDRESS_NOT_SET,
      showFromDate: false,
      showToDate: false,
      isFormValid: true,
      showGrid: false,
      loading: false,
      currentPage: 0,
      pageSize: 5,
      cartillaPrestadores: [],
      timer: null,
      prestadoresSearchResult: [],
      prestadorSearchInput: null,
      prestadorTextInput: null,
      origenesSearchResult: [],
      origenesLoading: false,
      origenSearchInput: null,
      toShowSelected: -1,
      esFarmacia: null,
      convenios: [],
      planes: [],
      especialidades: [],
      localidades: [],
      toShow: [
        {
          id: 1,
          value: "Ocultos"
        },
        {
          id: 2,
          value: "Visibles"
        }
      ],
      geolocToShow: [
        {
          id: 1,
          value: "Todos"
        },
        {
          id: 2,
          value: "Con geolocalización"
        },
        {
          id: 3,
          value: "Sin geolocalización"
        }
      ],
      farmacia: [
        {
          id: true,
          value: "Sólo farmacias"
        },
        {
          id: false,
          value: "Excluir farmacias"
        }
      ],
      openModalEdit: false,
      prestadorId: 0,
      origenId: 0,
      editIcon: enums.icons.EDIT,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      allowedActions: null,
      canEditInfo: false,
      canEditGeoLoc: false,
      itemsOrigen: []
    };
  },

  computed: {
    ...mapGetters({
      listModulos: "user/modulos",
      filtersTop: "prestadores/configuracionPrestadoresFiltersTop"
      // prestadoresSearchResult: "prestadores/prestadoresByQuery",
      // origenesSearchResult: "prestadores/origenesByQuery"
    }),
    google: gmapApi
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getGmapConfigMethod();
    this.$bus.$on("refreshTable", () => {
      this.applyFilters();
      this.loading = false;
    });
  },
  mounted() {
    this.getConfiguracionPrestadoresFiltersTop();
    this.options = { page: 1, itemsPerPage: 5 };
    this.currentPage = this.options.page;
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  watch: {
    filtersTop() {
      this.convenios = this.filtersTop.convenios.items;
      this.especialidades = this.filtersTop.especialidades.items;
      this.localidades = this.filtersTop.localidades.items;
      if (this.convenios && !this.convenios.find(x => x.value === "Todos"))
        this.convenios.unshift({ id: 0, value: "Todos" });
      if (
        this.especialidades &&
        !this.especialidades.find(x => x.value === "Todas")
      )
        this.especialidades.unshift({ id: 0, value: "Todas" });
      if (this.localidades && !this.localidades.find(x => x.value === "Todas"))
        this.localidades.unshift({ id: 0, value: "Todas" });
    },
    prestadorTextInput(val) {
      if (val && val.length >= 3) {
        this.getPrestadoresFilter();
      }
    },

    origenesAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getOrigenesFilter();
      }
    }
  },

  methods: {
    ...mapActions({
      getConfiguracionPrestadoresFiltersTop:
        "prestadores/getConfiguracionPrestadoresFiltersTop",
      getConfiguracionCartillaInfoTable:
        "prestadores/getConfiguracionCartillaInfoTable",
      fetchPrestadoresByQuery: "afiliaciones/fetchPrestadoresCartillaByQuery",
      fetchOrigenesByQuery: "prestadores/fetchOrigenesByQuery",
      getPlanesByOsId: "prestadores/getPlanesByOsId",
      getGmapConfig: "shared/getGmapConfig",
      putMassiveGeolocation: "prestadores/putMassiveGeolocation",
      setAlert: "user/setAlert"
    }),

    initMap() {
      if (this.$refs.myMap) {
        setTimeout(() => {
          this.$refs.myMap.$mapPromise.then(map => {
            var bounds = new google.maps.LatLngBounds();
            if (this.markers.length > 0) {
              if (this.markers.length == 1) {
                this.center = {
                  lat: this.markers[0].position.lat,
                  lng: this.markers[0].position.lng
                };
                this.zoom = 15;
                return;
              }
              this.markers.forEach(marker => {
                bounds.extend(
                  new google.maps.LatLng(
                    marker.position.lat,
                    marker.position.lng
                  )
                );
              });
              map.fitBounds(bounds);
            }
          }, 1000);
        });
      }
    },
    async getGmapConfigMethod() {
      const res = await this.getGmapConfig();
      this.gMapsApiKey = res;
      if (this.gMapsApiKey) {
        this.initGmapApi();
      }
    },
    initGmapApi() {
      // check if google maps api is already loaded
      if (typeof google === "object" && typeof google.maps === "object") return;
      loadGmapApi({
        key: this.gMapsApiKey
      });
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = `<b>${marker.infoText}</b>`;

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.EDITAR_INFO_CARTILLA:
            this.canEditInfo = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_UBICACION_CARTILLA:
            this.canEditGeoLoc = true;
            break;
          default:
            break;
        }
      });
    },
    closeSetAddressModal() {
      this.modalMapa = false;
      this.tabs = "lista";
      this.applyFilters();
    },

    editPrestador(open, preId, oriId) {
      this.openModalEdit = open;
      this.prestadorId = preId;
      this.origenId = oriId;
      this.$bus.$emit("openModalEdit", this.openModalEdit);
    },
    editAddres(prestador) {
      if (prestador.opcionDomicilio === 0) {
        this.setAlert({ type: "info", message: this.snbMsg });
        return;
      }
      this.prestadorSelected = prestador;
      this.modalMapa = true;
    },
    async loadPlanes(osId) {
      if (!osId) osId = 0;
      this.planSelected = null;
      this.planes = await this.getPlanesByOsId(osId);
      if (this.planes.length > 0) {
        this.planes.unshift({ id: 0, value: "Todos" });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.filtersApplyed = [];
      this.esFarmacia = null;
      this.loadPlanes(0);
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    selectedMarker(m) {
      if (this.canEditGeoLoc) {
        this.infoWinOpen = false;
        const prestador =
          m.opcionDomicilio == 2
            ? this.prestadoresFiltered.find(x => x.oriId === m.oriId)
            : this.prestadoresFiltered.find(
                x =>
                  x.preIdRadic === m.preIdRadic &&
                  x.tipoRadicId === m.tipoRadicId
              );

        if (prestador) {
          this.editAddres(prestador);
        } else {
          console.error("Error al abrir edición");
        }
      }
    },
    async applyFilters() {
      this.showGrid = true;
      this.loading = true;
      this.filtersApplyed = this.formatFilters(this.$refs["filters-form"]);
      this.showFiltersSelected = true;

      let prestadorTemp =
        this.prestadorSearchInput !== null
          ? this.prestadoresSearchResult.find(
              x => x.id === this.prestadorSearchInput
            )
          : null;
      let origenTemp =
        this.origenSearchInput !== null
          ? this.origenesSearchResult.find(x => x.id === this.origenSearchInput)
          : null;

      this.prestadorTemp = prestadorTemp;
      this.origenTemp = origenTemp;
      let nombrePrestador = this.prestadorSearchInput?.value.includes("-")
        ? this.prestadorSearchInput?.value.split("-")[1].trim()
        : "";
      let nombreOrigen = this.origenSearchInput?.value.includes("-")
        ? this.origenSearchInput?.value.split("-")[1].trim()
        : "";
      // configuro los parametros como los espera el endpoint
      const bodyParams = {
        preId: this.prestadorSearchInput ? this.prestadorSearchInput.id : null,
        nombrePrestador: nombrePrestador,
        oriId: this.origenSearchInput ? this.origenSearchInput.id : null,
        nombreOrigen: nombreOrigen,
        tipoPrestador: 0,
        osId: this.filtersTop.convenios.model
          ? this.filtersTop.convenios.model
          : 0,
        planId: this.planSelected ? this.planSelected : 0,
        espId: this.filtersTop.especialidades.model
          ? this.filtersTop.especialidades.model
          : 0,
        locId: this.filtersTop.localidades.model
          ? this.filtersTop.localidades.model
          : 0,
        mostrarOcultos:
          typeof this.toShowSelected === "undefined" ? -1 : this.toShowSelected,
        farmacias: this.esFarmacia
      };

      try {
        const response = await this.getConfiguracionCartillaInfoTable({
          bodyParams
        });
        this.cartillaPrestadores = response.prestadores;
        this.loading = false;
        this.setGeoLocCartillaTodos();
        if (this.geolocSelected == 2) {
          this.setGeoLocCartillaOnly();
        } else if (this.geolocSelected == 3) {
          this.setGeoLocCartillaNone();
        }
        this.setPrestadoresFiltered();
        if (this.gMapsApiKey) {
          this.showGmap = true;
          this.initMap();
        }
      } catch {
        this.loading = false;
      }
    },
    setGeoLocCartillaTodos() {
      this.cartillaPrestadores.forEach(item => {
        if (item.geographyLat !== null && item.geographyLong !== null) {
          item["geoloc"] = true;
        } else {
          item["geoloc"] = false;
        }
      });
    },
    setGeoLocCartillaOnly() {
      this.cartillaPrestadores = this.cartillaPrestadores.filter(x => x.geoloc);
    },
    setGeoLocCartillaNone() {
      this.cartillaPrestadores = this.cartillaPrestadores.filter(
        x => !x.geoloc
      );
    },
    setPrestadoresFiltered() {
      this.prestadoresFiltered = this.cartillaPrestadores.filter(
        x => x.geographyLat !== null && x.geographyLong !== null
      );
      this.markers = null;
      this.markers = [
        ...this.prestadoresFiltered.map(x => ({
          position: { lat: +x.geographyLat, lng: +x.geographyLong },
          infoText: `<b>${x.preNom}<br/>${x.oriNom}</b>`,
          opcionDomicilio: x.opcionDomicilio,
          preId: x.preId,
          oriId: x.oriId,
          preIdRadic: x.preIdRadic,
          tipoRadicId: x.tipoRadicId
        }))
      ];
    },
    getPrestadoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.prestadoresSearchResult?.some(
            x => x.value == this.prestadorTextInput
          )
        ) {
          return;
        } else {
          this.prestadoresLoading = true;
          const res = await this.fetchPrestadoresByQuery({
            input: this.prestadorTextInput
          });
          this.$refs["prestador-autocomplete"].cachedItems = [];
          this.prestadoresSearchResult = res;
          this.prestadoresLoading = false;
        }
      }, 500);
    },
    getOrigenesFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.origenesSearchResult?.some(
            x => x.value == this.origenesAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.origenesSearchResult = response;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    openFirstDialogGeolocalizacion() {
      this.openFirstModal = true;
    },
    closeModalGeolocalizacion() {
      this.openFirstModal = false;
      this.openSecondModal = false;
    },
    openSecondModalGeolocalizacion() {
      this.openSecondModal = true;
      this.openFirstModal = false;
    },
    async generarGeolocalizacionMasiva() {
      this.loadingGeolocalizacion = true;
      const response = await this.putMassiveGeolocation();
      if (response.status === 200) {
        this.loadingGeolocalizacion = false;
        this.openSecondModal = false;
        this.setAlert({
          type: "success",
          message: "Geolocalización masiva generada con éxito"
        });
        this.applyFilters();
      }
    },
    prestadorChange(data) {
      this.prestadorSearchInput = data;
    },
    formatFilters(ref) {
      if (typeof ref === "undefined") return;
      return ref.$children
        .map(f => {
          if (f.value)
            return { key: f.id, label: f.label, model: f.selectedItems };
          if (f.label == "Prestador/Farmacias")
            return { key: f.id, label: f.label, model: f.selectedItems };
          return null;
        })
        .filter(f => f);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="css">
#gmap-container {
  position: relative;
}
#gmap-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  margin: 0 auto;
  z-index: 1;
}
#prestadoresTable td {
  font-size: 0.755rem !important;
}
.icon-readonly {
  cursor: context-menu;
}
.my-placeholder .v-label {
  font-size: 0.8em;
  opacity: 1;
}
</style>
