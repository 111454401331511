<template>
  <div>
    <v-form ref="form" v-model="isValid" lazy-validation>
      <v-row justify="center">
        <v-dialog
          v-model="modalOpen"
          persistent
          max-width="600px"
          @keydown.esc="closeModal"
        >
          <v-card>
            <v-container>
              <v-card-title>
                <span class="headline">{{ title }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      v-model="currentName"
                      type="text"
                      label="Prestador"
                      dense
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0 pb-0">
                    <v-switch
                      v-model="hidden"
                      label="Ocultar prestador en cartilla"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!hidden" cols="12" md="6" class="pt-0 pb-0">
                    <v-text-field
                      type="text"
                      label="Nombre en cartilla"
                      v-model="bookName"
                      :hint="helpText1"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      v-model="currentNameOri"
                      type="text"
                      label="Origen"
                      dense
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0 pb-0">
                    <v-switch
                      v-model="hiddenOri"
                      label="Ocultar origen en cartilla"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!hiddenOri" cols="12" md="6" class="pt-0 pb-0">
                    <v-text-field
                      type="text"
                      label="Nombre en cartilla"
                      v-model="bookNameOri"
                      :hint="helpText1"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="number"
                      :rules="[
                        (v) => v >= 0 || '* Ingrese un número mayor a 0',
                      ]"
                      label="Orden en cartilla"
                      v-model="order"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="closeModal">
                  Cerrar
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  form="form"
                  :disabled="!isValid"
                  @click="saveChanges"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import enums from '@/utils/enums/index.js';


export default {
  name: "EditPrestadorCartilla",
  props: ["prestadorId", "origenId"],
  data() {
    return {
      helpText1: "Vacío muestra el nombre original del prestador",
      helpText2: "Vacío muestra el nombre original del origen",
      title: enums.titles.EDITAR_PRESTADORES_CARTILLA,
      isValid: false,
      modalOpen: false,
      currentName: "",
      bookName: "",
      hidden: false,
      prestOri: {},
      currentNameOri: "",
      bookNameOri: "",
      hiddenOri: false,
      order: 0,
    };
  },

  watch: {
    prestOri(val) {
      if (val) {
        this.prestOri = val;
        this.order = this.prestOri.ordenCartilla;
        this.currentName = this.prestOri.preNom;
        this.bookName = this.prestOri.nombreCartilla;
        this.hidden = this.prestOri.ocultarCartilla;
        this.currentNameOri = this.prestOri.oriNom;
        this.bookNameOri = this.prestOri.nombreCartillaOri;
        this.hiddenOri = this.prestOri.ocultarCartillaOri;
      }
    },
  },
  created() {
    this.prestOri;
    this.$bus.$on("openModalEdit", (data) => {
      this.modalOpen = data;
    });

    this.modalOpen = true;
    this.isValid = true;
    this.getPrestador(this.prestadorId, this.origenId);
  },

  methods: {
    ...mapActions("prestadores", ["getPrestadorById", "updatePrestadores"]),

    closeModal() {
      this.modalOpen = false;
      this.$emit("close", false);

      this.$refs.form.reset();
    },
    async saveChanges() {
      if (!this.isValid) return;
      const datos = {
        preId: this.prestadorId,
        nombreCartilla: this.bookName,
        ocultarCartilla: this.hidden,
        oriId: this.origenId,
        ordenCartilla: parseInt(this.order),
        nombreCartillaOri: this.bookNameOri,
        ocultarCartillaOri: this.hiddenOri,
      };
      const res = await this.updatePrestadores(datos);
      this.closeModal();
      this.$bus.$emit("refreshTable", true);
    },
    async getPrestador(prestadorId, origenId) {
      await this.getPrestadorById({ prestadorId, origenId }).then((res) => {
        this.prestOri = res;
      });
    },
  },
};
</script>

